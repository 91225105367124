import { useCallback, useLayoutEffect, useState } from 'react'
import { ContentInfo, MainInfo } from './components'
import { Container, WrapperPage } from './Install.styled'
import { Ratings } from '@pages/Install/components/Ratings'
import { Comments } from '@pages/Install/components/Comments'
import { WhatsNew } from '@pages/Install/components/WhatsNew'
import { Footer } from '@pages/Install/components/Footer'
import { useFetch } from '@shared/hooks'
import { Helmet } from 'react-helmet'

export const Install = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null)
  const [isLoading, setLoading] = useState(false)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [isInAppBrowser, setIsInAppBrowser] = useState(false)

  const { request } = useFetch()

  useLayoutEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      setDeferredPrompt(e)
    })

    // Проверка пользовательского агента
    const userAgent = navigator.userAgent || navigator.vendor
    const isFacebookApp = /FBAN\/FBIOS|FBAV\/[\d\.]+/.test(userAgent)
    const isTelegramApp = /Telegram/.test(userAgent)

    if (isFacebookApp || isTelegramApp) {
      setIsInAppBrowser(true)
    }

    const currentUrl = window.location.href
    const chromeIntentUrl = `intent://${currentUrl}#Intent;scheme=https;package=com.android.chrome;end`
    console.log('chromeIntentUrl', chromeIntentUrl)
  }, [])

  const handleInstall = useCallback(() => {
    console.log('deferredPrompt', deferredPrompt)
    if (deferredPrompt) {
      setLoading(true)
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then(async (choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt')

          if (sessionId) {
            const requestBody = {
              session_id: sessionId,
            }

            const url = 'https://api.analytical-space.com/api/application-pwa/installed/'

            // Запрос на установку
            await request(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            })
          }
        } else {
          console.log('User dismissed the install prompt')
        }
        setLoading(false)
        setDeferredPrompt(null)
      })
    }
  }, [sessionId, deferredPrompt])

  // Запрос при заходе на страницу Install
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const session_id = searchParams.get('session_id')
    const click_id = searchParams.get('click_id')
    if (session_id) {
      setSessionId(session_id)
      localStorage.setItem('sessionId', session_id)
    }
    if (click_id) {
      localStorage.setItem('clickId', click_id)
    }
  }, [])

  const onOpenInChrome = () => {
    const currentUrl = window.location.href
    const urlWithoutScheme = currentUrl.replace(/^https?:\/\//, '')
    const chromeIntentUrl = `intent://${urlWithoutScheme}#Intent;scheme=https;package=com.android.chrome;end`
    console.log('chromeIntentUrl', chromeIntentUrl)
    // Проверка, является ли устройство Android
    const isAndroid = /android/i.test(navigator.userAgent)

    if (isAndroid) {
      window.location.href = chromeIntentUrl
    } else {
      // Для других устройств (например, iOS) можно просто предложить пользователю открыть ссылку в основном браузере
      alert('Please open this link in your main browser: ' + currentUrl)
    }
  }

  return (
    <WrapperPage>
      <Helmet>
        <title>Crazy Time Game</title>
      </Helmet>
      <Container>
        <MainInfo
          isInAppBrowser={isInAppBrowser}
          onOpenInChrome={onOpenInChrome}
          isLoading={isLoading}
          handleInstall={handleInstall}
        />
        <ContentInfo />
        <Ratings />
        <Comments />
        <WhatsNew />
        <Footer />
      </Container>
    </WrapperPage>
  )
}
