import {
  Container,
  ContentAbout,
  ContentUpdate,
  DateUpdate,
  DescriptionAbout,
  ItemImage,
  ListImage,
  TextData,
  TitleAbout,
  TitleUpdate,
  WrapperData,
  WrapperTitle,
} from './ContentInfo.styled'
import img1 from './assets/img-1.jpg'
import img2 from './assets/img-2.jpg'
import img3 from './assets/img-3.jpg'
import img4 from './assets/img-4.jpg'
import { ArrowForward, ArrowDown } from '@shared/assets'
import { useState } from 'react'

export const ContentInfo: React.FC = () => {
  const [isDescription, setIsDescription] = useState(false)

  return (
    <Container>
      <ListImage>
        <ItemImage src={img1} alt={'img-1'} />
        <ItemImage src={img2} alt={'img-2'} />
        <ItemImage src={img3} alt={'img-3'} />
        <ItemImage src={img4} alt={'img-4'} />
      </ListImage>
      <ContentAbout>
        <WrapperTitle>
          <TitleAbout>About this game</TitleAbout>
          {isDescription ? (
            <ArrowDown onClick={() => setIsDescription(prev => !prev)} />
          ) : (
            <ArrowForward onClick={() => setIsDescription(prev => !prev)} />
          )}
        </WrapperTitle>
        <DescriptionAbout $isDescription={isDescription}>
          😱 Don't miss a chance - ৳100,000,000
          <br />
          🐺 Yo Yo Yo is connected! Download the app and get সরাসরি 2500 straight to your card! 💸
          <br />
          <br />
          🤑 In this casino created by my team of developers, I am giving millions of dollars to
          anyone who wishes!
          <br />
          <br />
          Just for you, I have created a whole lot of the best slots and games from all casinos
          around the world:
          <br />
          🍬 Aviator
          <br />
          📖 Book of RA
          <br />
          ⚡ ️ Gates of Olympus
          <br />
          ️📖 রা বই
          <br />
          🔮 Plinko
          <br />
          🐶 Dog house
          <br />
          🍭 Sugar rush
          <br />
          🐺 Wolf gold and many others! <br />
        </DescriptionAbout>
        <ContentUpdate>
          <TitleUpdate>Updated on</TitleUpdate>
          <DateUpdate>March 2, 2024</DateUpdate>
        </ContentUpdate>
        <ContentUpdate>
          <WrapperTitle>
            <TitleAbout>Data safety</TitleAbout>
            <ArrowForward />
          </WrapperTitle>
          <DescriptionAbout>
            In this section, developers can specify how applications collect and use data.
          </DescriptionAbout>
          <WrapperData>
            <TextData>No information.</TextData>
          </WrapperData>
        </ContentUpdate>
      </ContentAbout>
    </Container>
  )
}
