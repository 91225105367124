import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
import img14 from '@pages/Install/components/Comments/assets/14.png'
import img15 from '@pages/Install/components/Comments/assets/15.png'
import img16 from '@pages/Install/components/Comments/assets/16.png'
// import img17 from '@pages/Install/components/Comments/assets/17.png'
// import img18 from '@pages/Install/components/Comments/assets/18.png'
// import img19 from '@pages/Install/components/Comments/assets/19.png'
// import img20 from '@pages/Install/components/Comments/assets/20.png'
// import img21 from '@pages/Install/components/Comments/assets/21.png'
// import img22 from '@pages/Install/components/Comments/assets/22.png'
// import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'Dhanush Prasad Mane',
    countStarts: 5,
    date: 'April 8, 2024',
    comment:
      'Very nice application. I never thought that I could get a lot of money. I recommend everyone to download this application',
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'Preet Mohan Contractor',
    countStarts: 16,
    date: 'April 1, 2024',
    comment:
      "Crazy time app is really good. I didn't think it was that easy. You install it and it works immediately. Overall very good. So I thought it was another scam. But I was sure that was not the case.",
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'Baalkrishan Raman',
    countStarts: 5,
    date: 'March 29, 2024',
    comment:
      'Very user-friendly and intuitive interface. It is easy to find all the necessary functions and settings, and quickly switch between different games. Great work by the developers',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'Nilima Bumb',
    countStarts: 4,
    date: 'March 25, 2024',
    comment:
      "The graphics and design of the app are simply amazing! I'm a real casino fan, and Crazy Time really attracted me with its stylish and modern look",
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'অভিজিৎ সেন',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      'দারুণ অ্যাপ। আমরা এটি সুখ সাথে ব্যবহার করি। খুব সুবিধাজনক এবং বোঝার জন্য। খেলা করা স্পোর্টসে দান করা মজাদার.. আমি এই অ্যাপ্লিকেশনটি সুপারি করছি...',
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'Pravin Pradhan',
    countStarts: 5,
    date: 'March 9, 2024',
    comment:
      'The app is great!!! At first I did not think that I could win so much in the casino, but now I earn 1000 a day, and sometimes 5 thousand !!!! I recommend crazy time to everyone, of course, if you want to make money. In my opinion this is the best sports betting app. From my review, everyone will decide whether to download Top-x or not, but personally I recommend it!!!!',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'Ricky Samuel',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'An exciting and addictive casino app! CrazyTime really creates an atmosphere of excitement and excitement, and also offers a wide range of games for entertainment.But sometimes it loads badly',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'Payal Pirzada Rastogi',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'Crazy time is an excellent application, clear interface, excellent graphics, no lag. Even a novice can figure it out. But the main advantage is that you can also earn extra money here! This is absolutely great! I have been using this application for a long time, it has never let me down, the output to the card is very convenient! In general, I recommend it to everyone.',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'Owais Gopal',
    countStarts: 5,
    date: 'February 24, 2024',
    comment:
      'Amazing game, I really liked it, a lot of advantages, a lot of positive emotions, a lot of additional functions, if there are some shortcomings that need to be worked on, but overall I am satisfied with the application and the fact that Crazy Time did a great job Crazy Time it suited me perfectly, I really liked everyone and the mood was good',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'David Sura',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      "Crazy time app is top level, I really liked Crazy time, it's really great, the rates in Crazy time are as good as possible, I recommend Crazy time to everyone because it's really great, I like Crazy time really Come on, really download Crazy time, you will not regret here, you earn very quickly, you will not regret it, the money you earn will not be unnecessary, I recommend everyone to download Crazy time",
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'Rahim Chandra Sengupta',
    countStarts: 4,
    date: 'February 18, 2024',
    comment:
      'Great crazy time app. We use it with pleasure. Very convenient and understandable.. It is interesting to bet on sports.. I recommend this application...',
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'আরিফ আহমেদ',
    countStarts: 4,
    date: 'February 1, 2024',
    comment:
      'আমি আমার একাউন্টে টাকা ভরেছি, খেলেছি, এবং দেখুন, আমি জিতেছি! আমি নিজে নিজে চেষ্টা করার আগে এইসব গল্পগুলিতে খুব কমই বিশ্বাস করতাম। আর এখন আমি এটা প্রত্যেককে সাজেস্ট করলাম!',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'John Bir',
    countStarts: 5,
    date: 'January 30, 2024',
    comment:
      'Downloaded the excellent Crazy Time online casino app, good winnings, high odds and fast payouts. Very good!!!',
    peopleFound: 14,
  },
  {
    icon: img14,
    name: 'Sirish Singh Bhakta',
    countStarts: 4,
    date: 'January 28, 2024',
    comment:
      'Crazy Time works great and withdraws money quickly. I advise everyone to download this application',
    peopleFound: 67,
  },
  {
    icon: img15,
    name: 'Habib Sidhu',
    countStarts: 5,
    date: 'January 20, 2024',
    comment: 'Crazy Time is a great app that I recommend to everyone.',
    peopleFound: 0,
  },
  {
    icon: img16,
    name: 'Akshay Ramanathan',
    countStarts: 5,
    date: 'January 19, 2024',
    comment:
      'Crazy Time is a good application where you can make good money on bets and casinos.\n',
    peopleFound: 12,
  },
  // {
  //   icon: img17,
  //   name: 'Aniruddh Kar',
  //   countStarts: 5,
  //   date: 'January 28, 2024',
  //   comment:
  //     "Crazy time is the top best app for betting, it is absolutely amazing and starts from 20 to 12,000. Simply and easily. I used to play such bets and always lose, but in Aviator everything is fair, I never lose money and withdraw money with any payment method. Apart from this, there are many other wallets in which you can transfer money. If you don't believe me, check it out yourself!",
  //   peopleFound: 23,
  // },
  // {
  //   icon: img18,
  //   name: 'Srijit Rahman',
  //   countStarts: 5,
  //   date: 'January 12, 2024',
  //   comment:
  //     'Crazy time, great application for everyone and it has no problems and everything else is fine. Simple, intuitive application. Even a newbie can understand it, so you can download, install and start playing. In this direction, you can not only relax but also earn money by playing this game. Download and install.',
  //   peopleFound: 19,
  // },
  // {
  //   icon: img19,
  //   name: 'Maushum Choudhury',
  //   countStarts: 5,
  //   date: 'January 11, 2024',
  //   comment:
  //     'Crazy time is an excellent application, clear interface, excellent graphics, no lag. Even a novice can figure it out. But the main advantage is that you can also earn extra money here! This is absolutely great! I have been using this application for a long time, it has never let me down, the output to the card is very convenient! In general, I recommend it to everyone.',
  //   peopleFound: 43,
  // },
  // {
  //   icon: img20,
  //   name: 'Ranjit Chakravarty',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "A very excellent company Crazy time, big wins, fast payouts and high possibilities, many sports games, very convenient and easy to use, even a newbie can figure it out in a few clicks and you are already earning money. So far, I've only heard mediocre comments about this app. I recommend everyone to try",
  //   peopleFound: 26,
  // },
  // {
  //   icon: img21,
  //   name: 'Ajit Das',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "Very good application, I did not even think that you can win money on Crazy time, I advise everyone to install the Crazy time application, place bets and win money, because as they say, there is never too much money Have a chance to win with pleasure, and it's great, good luck to everyone who joins Aviator, good luck placing bets in this wonderful application.",
  //   peopleFound: 23,
  // },
  // {
  //   icon: img22,
  //   name: 'Manish Mukhopadhyay',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     'Very nice application. I never thought that I could get a lot of money. I recommend everyone to download this application',
  //   peopleFound: 19,
  // },
  // {
  //   icon: img23,
  //   name: 'Vikram Pande',
  //   countStarts: 5,
  //   date: 'December 27, 2023',
  //   comment:
  //     "Crazy time app is really good. I didn't think it was that easy. You install it and it works immediately. Overall very good. So I thought it was another scam. But I was sure that was not the case.",
  //   peopleFound: 67,
  // },
]
